import React from 'react'
import { Box, Button, Card, Flex, Heading, Image, Text } from '@asktia/tia-ui'
import careCoordinationImg from 'src/assets/care_coordination_team.png'
import { Link as RouterLink } from 'react-router-dom'

export const NoMessagesInbox = () => (
    <Flex sx={{ flexDirection: 'column', alignItens: 'flex-start' }}>
        <Heading h3>
            From scheduling questions to billing, test results to Rx refills,
            your Tia Care Team is here to help!
        </Heading>
        <Card
            variant="info"
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                mb: 5
            }}
        >
            <Heading h2 sx={{ textAlign: 'center', mb: 5 }}>
                <Box>Meet Your</Box>
                Care Coordination Team
            </Heading>
            <Text
                sx={{
                    textAlign: 'left',
                    variant: 'text.supportingInfo'
                }}
            >
                …like a personal assistant, but for your healthcare! Your Care
                Coordinators liaise with other members of your Care Team to get
                you accurate answers with speed & ease.
            </Text>
        </Card>

        <Button
            as={RouterLink}
            // @ts-ignore: TS doesn't understand objects can be indexed by string
            to="/chat/new"
            fullWidth
        >
            Send New Message
        </Button>
    </Flex>
)
