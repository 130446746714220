import { useCallback, PropsWithChildren, useState, useEffect } from 'react'
import { differenceInHours, isWeekend } from 'date-fns'
import { useForm, FieldValues } from 'react-hook-form'
import { ModalTitle, CallTia } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'
import {
    Container,
    Form,
    LayoutGrid,
    Select,
    Input,
    Button,
    Text,
    TiaModal,
    Box,
    Flex,
    Link,
    Label,
    Heading
} from '@asktia/tia-ui'
import { useCreateMessageThread } from 'src/hooks/useMessageThreads'
import { useLocation } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useAmpli, useHomeMarket, useNavigation } from 'src/hooks'
import { checkHoliday, isCampTiaDay } from 'src/utils'
import { useUpcomingAppointments } from 'src/hooks/useUpcomingAppointments'
import { ThreadLabel, THREAD_LABELS } from 'src/globals'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { TextareaWithAttachment } from '../Thread/TextareaWithAttachment'
import { createMessageForThread, FILE_UPLOAD_NAME } from '../Thread/useThread'
import { AttachmentClipIcon } from 'src/components/Blocks/Icons'
import { HelpArticle, HelpArticles } from 'src/pages/Chat/NewChat/HelpArticles'
import { useAmpliFeatureFlag } from 'src/AmplitudeExperimentProvider'
import { Variant } from '@amplitude/experiment-js-client'

type SelectValue = {
    label: string
    value: string
}

type FormType =
    | {
          message: string
          subject: string
          label: SelectValue
          [FILE_UPLOAD_NAME]?: FileList
      }
    | FieldValues

const isCareTeamAway = (timezone?: string) => {
    const now = new Date()
    return isWeekend(now) || checkHoliday(now, timezone) || isCampTiaDay(now)
}

const HolidayMessage = () => (
    <>
        <p>
            The Care Team at Tia is observing today as a holiday and is
            currently out of the Clinic.
        </p>
        <p>
            If this is an after-hours emergency, <b>please call 911</b>.
        </p>
        <p>
            If this is an urgent medical matter that requires attention prior to
            the next clinic opening, please call the following number (this
            number only accepts calls, not texts):{' '}
            <CallTia sx={{ fontSize: 6 }} />.
        </p>
        <p>
            Routine medical questions and refilling of Rx will not be handled
            through this line.
        </p>
    </>
)

const AwayMessage = () => (
    <>
        <p>
            If this is an after-hours emergency, <b>please call 911</b>.
        </p>
        <p>
            If this is an urgent medical matter that requires attention prior to
            the next clinic opening, please call the following number (this
            number only accepts calls, not texts):{' '}
            <CallTia sx={{ fontSize: 6 }} />.
        </p>
        <p>
            Routine medical questions and refilling of Rx will not be handled
            through this line.
        </p>
    </>
)

const AwayModal = ({
    hideModal,
    onSendMessage,
    sendingMessage
}: {
    hideModal: () => void
    onSendMessage: () => void
    sendingMessage: boolean
}) => {
    const nonWorkableDay = isWeekend(new Date()) || isCampTiaDay(new Date())
    const title = nonWorkableDay
        ? 'Even Tia needs her beauty sleep!'
        : 'The Care Team is currently out of the Clinic'

    return (
        <TiaModal>
            <ModalTitle title={title} hideModal={hideModal} />
            {nonWorkableDay ? <AwayMessage /> : <HolidayMessage />}
            <Button
                onClick={onSendMessage}
                sx={{ mb: 2, mt: 3 }}
                loading={sendingMessage}
                fullWidth
            >
                Send Message
            </Button>
            <Flex sx={{ justifyContent: 'center' }}>
                <Link sx={{ color: 'text' }} onClick={hideModal}>
                    Cancel
                </Link>
            </Flex>
        </TiaModal>
    )
}

const AlertBox = ({ children }: PropsWithChildren<{}>) => (
    <Box
        sx={{
            fontSize: 2,
            backgroundColor: 'cardBackground',
            color: 'text',
            borderRadius: 2,
            mb: 5,
            py: 4,
            px: 5
        }}
    >
        {children}
    </Box>
)

const CallUsAlert = () => {
    const { appointments, isLoading } = useUpcomingAppointments()
    const now = new Date()
    const apptWithin24hours = appointments?.some(
        a => differenceInHours(a.scheduledTime, now) <= 24
    )
    if (isLoading || !apptWithin24hours) {
        return null
    }

    return (
        <AlertBox>
            If you are reaching out about your upcoming appointment within 24
            hours, we recommend <CallTia>giving us a call</CallTia> if you have
            questions!
        </AlertBox>
    )
}

export type HelpArticleConfig = {
    articles: HelpArticle[]
    subject: string
    description?: string
    moreHelpLink?: { label: string; url: string }
}[]

export const NewChatVariant2 = () => {
    const { search } = useLocation()
    const [showSatisfactionButtons, setShowSatisfactionButtons] = useState(true)
    const searchParams = new URLSearchParams(search)
    const defaultValues: Partial<FormType> = {
        message: searchParams.get('message') || '',
        subject: searchParams.get('subject') || ''
    }
    const formMethods = useForm<FormType>({ defaultValues, mode: 'onChange' })
    const [wantsToContinue, setWantsToContinue] = useState(false)
    const { navigate } = useNavigation()
    const chosenLabel = formMethods.watch('label')
    const { loadedArticlesSubjectChat } = useAmpli()

    const ampli = useAmpli()

    const chatExperience = useAmpliFeatureFlag(
        'new-chat-experience',
        true
    ) as Variant
    const helpArticleConfig = chatExperience?.payload as HelpArticleConfig
    const helpArticlesForSubject = helpArticleConfig?.find(
        mapping => mapping.subject === chosenLabel?.value
    )
    const hasArticlesForSubject =
        chosenLabel?.value &&
        helpArticlesForSubject?.articles &&
        helpArticlesForSubject.articles.length > 0
    const skipArticlesHelp =
        chosenLabel?.value && !helpArticlesForSubject?.articles?.length

    if (
        searchParams.get('label') &&
        Object.values(THREAD_LABELS).includes(
            searchParams.get('label') as ThreadLabel
        )
    ) {
        defaultValues.label = {
            label: searchParams.get('label') as ThreadLabel,
            value: searchParams.get('label') as string
        }
    }

    const { homeMarket } = useHomeMarket()
    const { mutateAsync, isLoading } = useCreateMessageThread()
    const saveNewThread = useCallback(
        async (values: FormType) => {
            const redirectUrl = await mutateAsync({
                message: values.message,
                subject: values.subject,
                label: values.label.value
            })

            if (redirectUrl) {
                const threadIdRegex = /\/r\/chat\/thread\/(.*)/g
                const matches = threadIdRegex.exec(redirectUrl)

                const threadId = matches && parseInt(matches[1])

                if (
                    threadId &&
                    values[FILE_UPLOAD_NAME] &&
                    values[FILE_UPLOAD_NAME].length > 0
                ) {
                    await createMessageForThread(threadId, {
                        [FILE_UPLOAD_NAME]: values[FILE_UPLOAD_NAME],
                        body: ' '
                    })
                }

                window.location.href = redirectUrl
            }
        },
        [mutateAsync]
    )
    const [showModal, hideModal] = useModal(
        () => (
            <AwayModal
                onSendMessage={() => {
                    saveNewThread(formMethods.getValues())
                }}
                sendingMessage={isLoading}
                hideModal={hideModal}
            />
        ),
        [saveNewThread, isLoading]
    )
    const onSubmit = async (values: FormType) => {
        try {
            if (
                isCareTeamAway(homeMarket?.timezone) ||
                isCampTiaDay(new Date())
            ) {
                showModal()
            } else {
                await saveNewThread(values)
            }
        } catch (e) {
            // what should we do here ?
        }
    }

    useEffect(() => {
        if (hasArticlesForSubject) {
            loadedArticlesSubjectChat()
        }
    }, [hasArticlesForSubject])

    return (
        <Body
            sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '8'
            }}
        >
            <PageHeader
                mobileBack={<BackButton href="/r/your-care" variant="white" />}
            />
            <NavBarHeading titleAlign="center" sx={{ mb: 5 }}>
                Message Your <br /> Care Coordination Team
            </NavBarHeading>
            <LayoutGrid columns="one">
                <Container>
                    <Form onSubmit={onSubmit} useFormMethods={formMethods}>
                        <Select
                            validations={{ required: true }}
                            placeholder="What do you need help with today?"
                            name="label"
                            options={Object.values(THREAD_LABELS).map(l => ({
                                label: l,
                                value: l
                            }))}
                            onChange={() => {
                                setWantsToContinue(false)
                                setShowSatisfactionButtons(true)
                            }}
                        />

                        {chosenLabel?.value === 'Medical Question' && (
                            <AlertBox>
                                For medical emergencies, please dial 911.
                            </AlertBox>
                        )}
                        {chosenLabel?.value === 'Appointment Scheduling' && (
                            <CallUsAlert />
                        )}

                        {hasArticlesForSubject && (
                            <>
                                <HelpArticles
                                    description={
                                        helpArticlesForSubject.description
                                    }
                                    helpArticles={
                                        helpArticlesForSubject.articles
                                    }
                                    moreHelpLink={
                                        helpArticlesForSubject.moreHelpLink
                                    }
                                />
                                {showSatisfactionButtons && (
                                    <Box sx={{ mb: 5 }}>
                                        <Heading h2>
                                            Did these results help you get what
                                            you needed?
                                        </Heading>
                                        <Text as="div">
                                            If you didn't get what you needed, a
                                            Tia team member can help.
                                        </Text>

                                        <Button
                                            variant="secondaryDark"
                                            fullWidth={true}
                                            type="button"
                                            sx={{ mt: 4 }}
                                            onClick={() => {
                                                setShowSatisfactionButtons(
                                                    false
                                                )
                                                ampli.chatSatisfiedClicked()
                                                navigate('/chat')
                                            }}
                                        >
                                            Yes, I got what I needed
                                        </Button>
                                        <Button
                                            variant="secondaryDark"
                                            fullWidth={true}
                                            type="button"
                                            sx={{ mt: 4 }}
                                            onClick={() => {
                                                setShowSatisfactionButtons(
                                                    false
                                                )
                                                ampli.chatNotSatisfiedClicked()
                                                setWantsToContinue(true)
                                            }}
                                        >
                                            No, I want to talk to a Tia team
                                            member
                                        </Button>
                                    </Box>
                                )}
                            </>
                        )}

                        {(wantsToContinue || skipArticlesHelp) && (
                            <>
                                <Input
                                    validations={{ required: true }}
                                    name="subject"
                                    placeholder="Enter a subject for your message"
                                />

                                <TextareaWithAttachment
                                    textarea={{
                                        name: 'message',
                                        placeholder: 'Enter your message'
                                    }}
                                    attachment={{
                                        name: FILE_UPLOAD_NAME
                                    }}
                                    validations={{ required: true }}
                                    showAttachmentButton={false}
                                    formMethods={formMethods}
                                    sx={{ height: '136px' }}
                                />

                                <Label
                                    htmlFor="message-file-upload"
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mb: 2
                                    }}
                                >
                                    <AttachmentClipIcon
                                        color="inactiveText"
                                        hoverColor="inactiveText"
                                    />{' '}
                                    <Link
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Add attachment
                                    </Link>
                                </Label>
                                <Text
                                    variant="supportingInfo"
                                    sx={{
                                        textAlign: 'center'
                                    }}
                                >
                                    You can chat with your Care Coordinators
                                    M-F, 5AM - 6PM PDT. A Care Coordinator will
                                    respond within 48 hours, although typically
                                    the same day for urgent needs! For medical
                                    emergencies, please call 911.
                                </Text>

                                <Button
                                    disabled={
                                        Object.keys(formMethods.errors).length >
                                        0
                                    }
                                    loading={
                                        formMethods.formState.isSubmitting ||
                                        isLoading
                                    }
                                    type="submit"
                                    fullWidth
                                    sx={{ mt: 6 }}
                                >
                                    Send Message
                                </Button>
                            </>
                        )}
                    </Form>
                </Container>
            </LayoutGrid>
        </Body>
    )
}
