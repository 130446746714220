import { useCallback, PropsWithChildren } from 'react'
import { differenceInHours, isWeekend } from 'date-fns'
import { useForm, FieldValues } from 'react-hook-form'
import { ModalTitle, CallTia } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'
import {
    Container,
    Form,
    LayoutGrid,
    Select,
    Input,
    Button,
    Text,
    TiaModal,
    Box,
    Flex,
    Link,
    Label
} from '@asktia/tia-ui'
import { useCreateMessageThread } from 'src/hooks/useMessageThreads'
import { useLocation } from 'react-router-dom'
import { useModal } from 'react-modal-hook'
import { useHomeMarket } from 'src/hooks'
import { checkHoliday, isCampTiaDay } from 'src/utils'
import { useUpcomingAppointments } from 'src/hooks/useUpcomingAppointments'
import { ThreadLabel, THREAD_LABELS } from 'src/globals'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { TextareaWithAttachment } from '../Thread/TextareaWithAttachment'
import { createMessageForThread, FILE_UPLOAD_NAME } from '../Thread/useThread'
import { AttachmentClipIcon } from 'src/components/Blocks/Icons'

type SelectValue = {
    label: string
    value: string
}

type FormType =
    | {
          message: string
          subject: string
          label: SelectValue
          [FILE_UPLOAD_NAME]?: FileList
      }
    | FieldValues

export const isCareTeamAway = (timezone?: string) => {
    const now = new Date()
    return isWeekend(now) || checkHoliday(now, timezone) || isCampTiaDay(now)
}

const HolidayMessage = () => (
    <>
        <p>
            The Care Team at Tia is observing today as a holiday and is
            currently out of the Clinic.
        </p>
        <p>
            If this is an after-hours emergency, <b>please call 911</b>.
        </p>
        <p>
            If this is an urgent medical matter that requires attention prior to
            the next clinic opening, please call the following number (this
            number only accepts calls, not texts):{' '}
            <CallTia sx={{ fontSize: 6 }} />.
        </p>
        <p>
            Routine medical questions and refilling of Rx will not be handled
            through this line.
        </p>
    </>
)

const AwayMessage = () => (
    <>
        <p>
            If this is an after-hours emergency, <b>please call 911</b>.
        </p>
        <p>
            If this is an urgent medical matter that requires attention prior to
            the next clinic opening, please call the following number (this
            number only accepts calls, not texts):{' '}
            <CallTia sx={{ fontSize: 6 }} />.
        </p>
        <p>
            Routine medical questions and refilling of Rx will not be handled
            through this line.
        </p>
    </>
)

export const AwayModal = ({
    hideModal,
    onSendMessage,
    sendingMessage
}: {
    hideModal: () => void
    onSendMessage: () => void
    sendingMessage: boolean
}) => {
    const nonWorkableDay = isWeekend(new Date()) || isCampTiaDay(new Date())
    const title = nonWorkableDay
        ? 'Even Tia needs her beauty sleep!'
        : 'The Care Team is currently out of the Clinic'

    return (
        <TiaModal>
            <ModalTitle title={title} hideModal={hideModal} />
            {nonWorkableDay ? <AwayMessage /> : <HolidayMessage />}
            <Button
                onClick={onSendMessage}
                sx={{ mb: 2, mt: 3 }}
                loading={sendingMessage}
                fullWidth
            >
                Send Message
            </Button>
            <Flex sx={{ justifyContent: 'center' }}>
                <Link sx={{ color: 'text' }} onClick={hideModal}>
                    Cancel
                </Link>
            </Flex>
        </TiaModal>
    )
}

const AlertBox = ({ children }: PropsWithChildren<{}>) => (
    <Box
        sx={{
            fontSize: 2,
            backgroundColor: 'cardBackground',
            color: 'text',
            borderRadius: 2,
            mb: 5,
            py: 4,
            px: 5
        }}
    >
        {children}
    </Box>
)

const CallUsAlert = () => {
    const { appointments, isLoading } = useUpcomingAppointments()
    const now = new Date()
    const apptWithin24hours = appointments?.some(
        a => differenceInHours(a.scheduledTime, now) <= 24
    )
    if (isLoading || !apptWithin24hours) {
        return null
    }

    return (
        <AlertBox>
            If you are reaching out about your upcoming appointment within 24
            hours, we recommend <CallTia>giving us a call</CallTia> if you have
            questions!
        </AlertBox>
    )
}

export const NewChatVariant1 = () => {
    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const defaultValues: Partial<FormType> = {
        message: searchParams.get('message') || '',
        subject: searchParams.get('subject') || ''
    }

    if (
        searchParams.get('label') &&
        Object.values(THREAD_LABELS).includes(
            searchParams.get('label') as ThreadLabel
        )
    ) {
        defaultValues.label = {
            label: searchParams.get('label') as ThreadLabel,
            value: searchParams.get('label') as string
        }
    }

    const formMethods = useForm<FormType>({ defaultValues, mode: 'onChange' })
    const { homeMarket } = useHomeMarket()
    const { mutateAsync, isLoading } = useCreateMessageThread()
    const saveNewThread = useCallback(
        async (values: FormType) => {
            const redirectUrl = await mutateAsync({
                message: values.message,
                subject: values.subject,
                label: values.label.value
            })

            if (redirectUrl) {
                const threadIdRegex = /\/r\/chat\/thread\/(.*)/g
                const matches = threadIdRegex.exec(redirectUrl)

                const threadId = matches && parseInt(matches[1])

                if (
                    threadId &&
                    values[FILE_UPLOAD_NAME] &&
                    values[FILE_UPLOAD_NAME].length > 0
                ) {
                    await createMessageForThread(threadId, {
                        [FILE_UPLOAD_NAME]: values[FILE_UPLOAD_NAME],
                        body: ' '
                    })
                }

                window.location.href = redirectUrl
            }
        },
        [mutateAsync]
    )
    const [showModal, hideModal] = useModal(
        () => (
            <AwayModal
                onSendMessage={() => {
                    saveNewThread(formMethods.getValues())
                }}
                sendingMessage={isLoading}
                hideModal={hideModal}
            />
        ),
        [saveNewThread, isLoading]
    )
    const onSubmit = async (values: FormType) => {
        try {
            if (
                isCareTeamAway(homeMarket?.timezone) ||
                isCampTiaDay(new Date())
            ) {
                showModal()
            } else {
                await saveNewThread(values)
            }
        } catch (_e) {
            // what should we do here ?
        }
    }

    return (
        <Body
            sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '8'
            }}
        >
            <PageHeader
                mobileBack={<BackButton href="/r/your-care" variant="white" />}
            />
            <NavBarHeading titleAlign="center" sx={{ mb: 5 }}>
                Message Your <br /> Care Coordination Team
            </NavBarHeading>
            <LayoutGrid columns="one">
                <Container>
                    <Form onSubmit={onSubmit} useFormMethods={formMethods}>
                        <Select
                            validations={{ required: true }}
                            placeholder="What do you need help with today?"
                            name="label"
                            options={Object.values(THREAD_LABELS).map(l => ({
                                label: l,
                                value: l
                            }))}
                        />

                        {formMethods.watch('label')?.value ===
                            'Medical Question' && (
                            <AlertBox>
                                For medical emergencies, please dial 911.
                            </AlertBox>
                        )}
                        {formMethods.watch('label')?.value ===
                            'Appointment Scheduling' && <CallUsAlert />}

                        <Input
                            validations={{ required: true }}
                            name="subject"
                            placeholder="Enter a subject for your message"
                        />

                        <TextareaWithAttachment
                            textarea={{
                                name: 'message',
                                placeholder: 'Enter your message'
                            }}
                            attachment={{
                                name: FILE_UPLOAD_NAME
                            }}
                            validations={{ required: true }}
                            showAttachmentButton={false}
                            formMethods={formMethods}
                            sx={{ height: '136px' }}
                        />

                        <Label
                            htmlFor="message-file-upload"
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                mb: 2
                            }}
                        >
                            <AttachmentClipIcon
                                color="inactiveText"
                                hoverColor="inactiveText"
                            />{' '}
                            <Link
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                Add attachment
                            </Link>
                        </Label>

                        <Text
                            variant="supportingInfo"
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            Heads up, this is not a live chat and our team
                            responds within 3 business days. We recommend
                            avoiding sending multiple messages about the same
                            issue. This will move your message down the queue
                            and could delay our response. If you’re experiencing
                            an emergency, please call 911. We appreciate your
                            understanding.
                        </Text>

                        <Button
                            disabled={
                                Object.keys(formMethods.errors).length > 0
                            }
                            loading={
                                formMethods.formState.isSubmitting || isLoading
                            }
                            type="submit"
                            fullWidth
                            sx={{ mt: 6 }}
                        >
                            Send Message
                        </Button>
                    </Form>
                </Container>
            </LayoutGrid>
        </Body>
    )
}
