import { PropsWithChildren, useCallback } from 'react'
import { differenceInHours } from 'date-fns'
import { useForm, FieldValues } from 'react-hook-form'
import { CallTia } from 'src/components/Blocks'
import { PageHeader } from 'src/components/PageHeader'
import {
    Container,
    Form,
    LayoutGrid,
    Select,
    Input,
    Button,
    Text,
    Box
} from '@asktia/tia-ui'
import { useLocation } from 'react-router-dom'
import { useUpcomingAppointments } from 'src/hooks/useUpcomingAppointments'
import { ThreadLabel, THREAD_LABELS } from 'src/globals'
import { BackButton } from 'src/components/Blocks/BackButton'
import { Body } from 'src/components/Blocks/Body'
import { NavBarHeading } from 'src/components/Blocks/NavBarHeading'
import { TextareaWithAttachment } from '../Thread/TextareaWithAttachment'
import { FILE_UPLOAD_NAME } from '../Thread/useThread'
import { useNavigation } from 'src/hooks'
import { useCreateMessageThread } from 'src/hooks/useMessageThreads'

type SelectValue = {
    label: string
    value: string
}

type FormType =
    | {
          message: string
          subject: string
          label: SelectValue
          [FILE_UPLOAD_NAME]?: FileList
      }
    | FieldValues

const AlertBox = ({ children }: PropsWithChildren<{}>) => (
    <Box
        sx={{
            fontSize: 2,
            backgroundColor: 'cardBackground',
            color: 'text',
            borderRadius: 2,
            mb: 5,
            py: 4,
            px: 5
        }}
    >
        {children}
    </Box>
)

const CallUsAlert = () => {
    const { appointments, isLoading } = useUpcomingAppointments()
    const now = new Date()
    const apptWithin24hours = appointments?.some(
        a => differenceInHours(a.scheduledTime, now) <= 24
    )
    if (isLoading || !apptWithin24hours) {
        return null
    }

    return (
        <AlertBox>
            If you are reaching out about your upcoming appointment within 24
            hours, we recommend <CallTia>giving us a call</CallTia> if you have
            questions!
        </AlertBox>
    )
}

const SKIP_EXPERIMENT_FOR_TYPES = [
    'Medical Question',
    'Forms & Documents',
    'Other',
    'Prescription Refill',
    'Share Feedback'
]

export const NewChatVariant3 = () => {
    const { mutateAsync, isLoading } = useCreateMessageThread()
    const { navigate } = useNavigation()

    const { search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const defaultValues: Partial<FormType> = {
        message: searchParams.get('message') || '',
        subject: searchParams.get('subject') || ''
    }

    if (
        searchParams.get('label') &&
        Object.values(THREAD_LABELS).includes(
            searchParams.get('label') as ThreadLabel
        )
    ) {
        defaultValues.label = {
            label: searchParams.get('label') as ThreadLabel,
            value: searchParams.get('label') as string
        }
    }

    const formMethods = useForm<FormType>({ defaultValues, mode: 'onChange' })

    const sendToBotChat = (values: FormType) => {
        const message = values.message
        const subject = values.subject
        const label = values.label.value

        navigate(
            `/chat/help?label=${label}&subject=${subject}&message=${message}&created=${new Date().toISOString()}`
        )
    }

    const saveNewThread = useCallback(
        async (values: FormType) => {
            const redirectUrl = await mutateAsync({
                message: values.message,
                subject: values.subject,
                label: values.label.value
            })

            if (redirectUrl) {
                window.location.href = redirectUrl
            }
        },
        [mutateAsync]
    )

    const onSubmit = async (values: FormType) => {
        if (SKIP_EXPERIMENT_FOR_TYPES.includes(values?.label?.value)) {
            await saveNewThread(values)
        } else {
            sendToBotChat(values)
        }
    }

    return (
        <Body
            sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '8'
            }}
        >
            <PageHeader
                mobileBack={<BackButton href="/r/your-care" variant="white" />}
            />
            <NavBarHeading titleAlign="center" sx={{ mb: 5 }}>
                Message Your <br /> Care Coordination Team
            </NavBarHeading>
            <LayoutGrid columns="one">
                <Container>
                    <Form onSubmit={onSubmit} useFormMethods={formMethods}>
                        <Select
                            validations={{ required: true }}
                            placeholder="What do you need help with today?"
                            name="label"
                            options={Object.values(THREAD_LABELS).map(l => ({
                                label: l,
                                value: l
                            }))}
                        />

                        {formMethods.watch('label')?.value ===
                            'Medical Question' && (
                            <AlertBox>
                                For medical emergencies, please dial 911.
                            </AlertBox>
                        )}
                        {formMethods.watch('label')?.value ===
                            'Appointment Scheduling' && <CallUsAlert />}

                        <Input
                            validations={{ required: true }}
                            name="subject"
                            placeholder="Enter a subject for your message"
                        />

                        <TextareaWithAttachment
                            textarea={{
                                name: 'message',
                                placeholder: 'Enter your message'
                            }}
                            attachment={{
                                name: FILE_UPLOAD_NAME
                            }}
                            validations={{ required: true }}
                            showAttachmentButton={false}
                            formMethods={formMethods}
                            sx={{ height: '136px' }}
                        />

                        <Text
                            variant="supportingInfo"
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            Heads up, this is not a live chat and our team
                            responds within 3 business days. We recommend
                            avoiding sending multiple messages about the same
                            issue. This will move your message down the queue
                            and could delay our response. If you’re experiencing
                            an emergency, please call 911. We appreciate your
                            understanding.
                        </Text>

                        <Button
                            disabled={
                                Object.keys(formMethods.errors).length > 0 ||
                                isLoading
                            }
                            type="submit"
                            fullWidth
                            sx={{ mt: 6 }}
                        >
                            Send Message
                        </Button>
                    </Form>
                </Container>
            </LayoutGrid>
        </Body>
    )
}
