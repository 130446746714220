import { useEffect } from 'react'
import { useUserInfo } from '../../hooks/useUserInfo'
import { useAmpli } from '../../hooks/useAmpli'
/**
 * This component is a utility to track iteratively events for Identifying a user upon component-mount.
 * Instead of manually calling the Identify event on page-navigation, this component auto-identify the
 * user and handle the logic to avoid several tracks per render.
 *
 * Usage:
 * ```
 * <>
 *     <ItlyIdentify />
 * </>
 * ```
 *
 *
 */

export const AmpliIdentify = () => {
    // Ensures that the event is tracked only once on page mount
    const { user } = useUserInfo()
    const { identify } = useAmpli()

    useEffect(() => {
        if (user && user.analyticsUuid) {
            identify(user)
        }
    }, [user, identify])

    return null
}
